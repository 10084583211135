import Model from '@/dc-it/models/Model'
import Field from '@/dc-it/types/Field'
import Relation from '@/dc-it/types/Relation'

export default class BlogCategory extends Model {
    endpoint = 'blog_categories';

    related = ['blog_category']

    fields = [
      new Field('id', 'Código').disabled(),
      new Relation('blog_category', 'Categoría padre', BlogCategory, 'name_es').setClearable(),
      new Field('name_es', 'Nombre en español'),
      new Field('name_en', 'Nombre en inglés'),
    ]

    clone = () => BlogCategory;
}
